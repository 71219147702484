import React, { useState, useEffect } from "react";
import "./check-balance.scss";
import "@socialcard/uikit/dist/index.css";
import {
	Input,
	Button,
	Heading,
	Text,
	InfoTable,
	InfoTableItem,
	Checkbox,
	Spacing
} from "@socialcard/uikit";
import { backendServer } from "@constants/common";

const ERROR = "Сервис временно недоступен";

const CheckBalance = () => {
	const [value, setValue] = useState("");
	const [loading, setLoading] = useState(false);
	const [formIsValid, setFormIsValid] = useState(false);
	const [checked, setChecked] = useState(true);
	const [data, setData] = useState(undefined);
	const [error, setError] = useState("");

	const handleChangeInput = value => {
		setValue(value);
	};

	const handleChangeCheckbox = () => {
		setChecked(!checked);
	};

	useEffect(() => {
		if (
			checked &&
			(value.length === 10 || value.length === 16) &&
			Number.isInteger(+value)
		) {
			setFormIsValid(true);
		} else {
			setFormIsValid(false);
		}
	}, [checked, value]);

	const handleSubmit = e => {
		e.preventDefault();
		setError("");
		setData(undefined);

		if (formIsValid) {
			setLoading(true);

			fetch(`${backendServer}/api/getCardBalance`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json;charset=utf-8"
				},
				body: JSON.stringify({ card: value })
			})
				.then(res => {
					if (res.status === 200) {
						return res.json();
					} else {
						setError(ERROR);
					}
				})
				.then(res => {
					const { success, errorText, data } = res;
					if (success) {
						setData(data);
					} else {
						setError(errorText || ERROR);
					}
					setLoading(false);
				})
				.catch(() => {
					setLoading(false);
					setError(ERROR);
				});
		}
	};

	return (
		<section className="full-width check-balance-page">
			<div className="grid-container">
				<div className="row">
					<div className="col-xs-12 col-md-offset-2 col-md-8 col-sm-offset-1 col-sm-10">
						<Heading strong gutterBottom element="h2" text="Проверка баланса" />
					</div>

					<div className="col-xs-12 col-md-offset-2 col-md-6 col-sm-offset-1 col-sm-10">
						<Text>Введите номер вашей транспортной карты</Text>
						<form onSubmit={handleSubmit} className="form-check-balance">
							<Input
								maxLength={16}
								label="Номер карты"
								value={value}
								name="card"
								onChange={handleChangeInput}
							/>
							{data && (
								<InfoTable className="form-check-balance__result">
									{data.tariff && (
										<InfoTableItem label="Ваш тариф: " value={data.tariff} />
									)}
									{data.comment && (
										<InfoTableItem label="Статус: " value={`${data.comment}`} />
									)}
									{(data.amount || data.amount === 0) && (
										<InfoTableItem
											label="Ваш баланс: "
											value={`${data.amount} ₽`}
										/>
									)}
								</InfoTable>
							)}
							{error && (
								<Spacing bottom>
									<Text color="error">{error}</Text>
								</Spacing>
							)}
							<Checkbox
								id="checkCardBalance"
								name="checkCardBalance"
								checked={checked}
								onChange={handleChangeCheckbox}
							>
								<Text className="form-check-balance__text">
									Право пользования данным сервисом имеет только владелец
									электронного проездного билета "Социальная карта". Владельцем
									сервиса является АО "Социальная карта".
								</Text>
								<Text className="form-check-balance__text">
									Категорически запрещается использование сервиса третьими
									лицами с целью получения конфиденциальной информации.
								</Text>
								<Text className="form-check-balance__text">
									АО "Социальная карта" оставляет за собой право отказать в
									использовании сервиса без объяснения причин.
								</Text>
							</Checkbox>
							<Button
								loading={loading}
								className="button"
								disabled={!formIsValid}
							>
								Запросить
							</Button>
						</form>
					</div>
				</div>
			</div>
		</section>
	);
};

export default CheckBalance;
